@import '../../node_modules/react-phone-number-input/style.css';
@import '../../node_modules/@amir04lm26/react-modern-calendar-date-picker/lib/DatePicker.css';

@import 'fonts';
@import 'mixins';
@import 'constants';
@import '/src/components/_shared/phoneNumberInput/PhoneNumberStyles.scss';
@import '/src/components/_shared/gallery/lightbox.scss';

html,
body {
  padding: 0;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

body {
  max-width: $content-max-width;
  margin: 0 auto !important;
  float: none !important;
  background-color: $rove-off-white;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;

  &:focus-visible {
    outline: 0;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 50px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.scrollable {
  overflow: hidden;
}

.clickable {
  &:hover {
    cursor: pointer;
  }
}

// Google maps info window styles
#info-window {
  overflow: hidden !important;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  margin: 6px 12px 16px 0;
  display: block;
  min-width: 320px;
  max-width: 380px;
  cursor: pointer;

  @include media('<=laptop') {
    min-width: unset;
  }

  .upper-block {
    padding-top: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    column-gap: 12px;

    @include media('<=laptop') {
      padding-top: 0;
    }
  }

  .upper-block-info {
    flex: 1;
  }

  .upper-block-image-wrapper {
    flex: 1;
    position: relative;

    @include media('<=laptop') {
      flex: 0;
    }
  }

  .bottom-block {
    display: flex;
    flex-direction: row;
    flex: 1;
    text-align: left;
  }

  .listing-image {
    width: 191px;
    min-width: 191px;
    height: 122px;
    object-fit: cover;
    border-radius: 8px;
    overflow: hidden;
  }

  .listing-title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 3px;
    color: $rove-dark-black;
    max-width: 90%;

    @include media('<=laptop') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .listing-address {
    font-size: 12px;
    line-height: 16px;
    color: $rove-dark-black;
  }

  .listing-feature {
    div:first-child {
      font-size: 12px;
      line-height: 18px;
      color: $rove-darker-tan;
    }

    div:last-child {
      color: $rove-black;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 4px 0 0;
    }
  }

  .listing-feature:first-child {
    margin-right: 16px;
    padding-right: 16px;
    border-right: 1px solid $rove-tan;
  }

  .listing-feature:last-child {
    margin-left: auto;

    div:last-child {
      text-align: right;
    }
  }

  .listing-crossed-price {
    margin-right: 7px;
    font-weight: 400;
    color: $rove-light-tan;
    text-decoration: line-through;
  }
}

// iw stands for InfoWindow, iw-d - for dialog. There is no better
// solution to custom the built-in popups. The only alternative is
// to create a custom popup with all the rewritten methods.

.gm-style {
  &-iw {
    overflow-y: auto !important;
    overflow-x: hidden !important;
    background: #ffffff !important;
    border-radius: 10px !important;
    border: 1px solid $rove-light-tan !important;
    box-shadow: 0 10px 10px 10px rgba(182, 182, 182, 0.25) !important;

    &-d {
      overflow: visible !important;
    }

    &-chr {
      position: absolute !important;
      top: 0 !important;
      right: 0 !important;
    }
  }

  * {
    &:after {
      display: none !important;
    }
  }
}

.gm-style-iw > div {
  overflow: visible !important;
}

.gm-ui-hover-effect {
  opacity: 1 !important;
  & > span {
    background-color: #dddddd !important;
  }
}

.rc-slider-rail {
  height: 3px !important;
  background: $rove-tan !important;
}
.rc-slider-track {
  height: 3px !important;
  background: $rove-blue !important;
}

.rc-slider-handle {
  background: $rove-blue !important;
  border: none !important;
  opacity: 1 !important;
  &-dragging {
    box-shadow: 0 0 5px $rove-blue !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.with-opacity-hover {
  cursor: pointer;
  transition: 0.2s linear all;
  user-select: none;

  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.6;
  }
}

.with-parallax {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  transform: translate3d(0, 0, 0);
  will-change: contents;

  @include media('<=laptop') {
    background-attachment: scroll;
  }
}

.fade-animation {
  animation: fade 0.5s ease;
  animation-fill-mode: backwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fadein-animation {
  animation: fade 1.5s ease;
  animation-fill-mode: backwards;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fslightbox-toolbar {
  div:first-of-type {
    display: none;
  }
}

.skeleton-box {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: $rove-border-gray;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.amenity-tooltip {
  .rc-tooltip-arrow {
    display: none;
  }

  .rc-tooltip-inner {
    background-color: $rove-white;
    filter: drop-shadow(0px 0px 4px $rove-green);
    color: $rove-green;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    border-radius: 20px;
  }
}

.calendar-listing-tooltip {
  opacity: 1 !important;

  .rc-tooltip-arrow {
    border-top-color: $rove-off-white !important;
  }

  .rc-tooltip-inner {
    box-shadow: 0 0 4px 0 #00000040;
    background-color: $rove-off-white;
    color: $rove-dark-tan;
    font-weight: 400;
    opacity: 1;
    font-style: normal;
    border-radius: 8px;
    font-size: 11px;
    line-height: 16.5px;
    text-align: left;
    max-width: 220px;
  }
}

.admin-tooltip {
  opacity: 1 !important;

  .rc-tooltip-arrow {
    border-top-color: $rove-off-white !important;
  }

  .rc-tooltip-inner {
    background-color: $rove-off-white;
    box-shadow: 0 0 4px 0 #00000040;
    opacity: 1;
    color: $rove-tan;
    font-style: normal;
    border-radius: 8px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16.5px;
    text-align: left;
    max-width: 240px;
  }
}

.calendar-reservation-tooltip {
  .rc-tooltip-inner {
    padding: 0 !important;
  }

  &__header {
    padding: 8px 8px 4px;
  }

  &__body {
    border-radius: 0 0 8px 8px;
    min-width: 190px;
    padding: 8px;
    background-color: $rove-white;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
  }

  &__label {
    color: $rove-dark-black;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: left;
  }

  &__value {
    color: $rove-dark-black;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    text-align: right;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;

    &.is-highlighted {
      color: $rove-failed;
    }
  }
}

$rove-black: #1d1919;
$rove-white: #ffffff;
$rove-off-white: #f8f4f1;
$rove-tan: #c3beb4;
$rove-green: #859485;
$rove-dark-green: #5cb3b6;
$rove-orange: #f0beaa;
$rove-blue: #aad4d2;
$rove-light-blue: #70c5c1;
$rove-light-gray: #aaaaaa;
$rove-medium-gray: #606060;
$rove-dark-black: #000000;
$rove-gray: #878787;
$rove-light-background: #fcfaf8;
$rove-red: change-color(
  $color: red,
  $alpha: 0.7,
);
$rove-border-gray: #d4d4d4;
$rove-border-gray-50: rgba(212, 212, 212, 0.5);
$rove-failed: #eb9978;
$rove-light-tan: #8e8b84;
$rove-dark-tan: #8f8b83;
$rove-darker-tan: #524e47;
$rove-border-color: #dddddd;
$rove-semi-accent: #c7c5c1;

$header-height: 75px;
$search-bar-height: 67px;
$content-max-width: 100vw;
$site-max-width: 1440px;

$breakpoints: (
  phone: 486px,
  tablet: 768px,
  laptop: 1024px,
);

$background-image-z-index: -1;
$calendar-z-index: 1;
$selector-z-index: 2;
$video-z-index: 2;
$video-overflow-z-index: 3;
$text-over-video-z-index: 4;
$more-filters-z-index: 3;
$menu-z-index: 11;
$header-z-index: 99;
$burger-menu-z-index: 999;

$button-height: 50px;

$desktop-page-indent: 5vw;
$mobile-page-indent: 16px;
$mobile-page-padding-top: 70px;
